/* ============================ sliders.js START ================================================================= */
const gallery = new Swiper('.slider-gallery', {
   // Default parameters
   //к-сть слайдів яку показує
   slidesPerView: 1,
   //відключення ф-лу якщо слайдів менше чим потрібно
   // watchOverflow: true,
   //відступи між слайдами
   spaceBetween: 24,
   //швидкість показу слайдів
   speed: 300,
   //к-сть прокручуюмих слайдів
   // slidesPerGroup: 1,
   //безкінечний слайдер(по кругу)
   // loop: true,
   // Responsive breakpoints
   breakpoints: {
      // when window width is >= 320px
      320: {
         slidesPerView: 1.1,
         spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
         slidesPerView: 1.1,
         spaceBetween: 10,
      },
      667: {
         slidesPerView: 1,
         spaceBetween: 24,
      },
   },

   pagination: {
      el: '.slider-gallery__pagination',
      type: 'bullets',
      clickable: true,
      // dynamicBullets: true,
   },
   // autoplay: {
   //    //швидкість перемикання
   //    delay: 5000,
   //    //закінчити на останьому слайді
   //    stopOnLastSlide: true,
   //    //відключити після ручного перемикання
   //    disableOnInteraction: false,
   // },
   //затримка для певного слайду на n-сек
   <!-- hold this slide for 2 seconds -->
   // <div class="slider-gallery-slide" data-slider-gallery-autoplay="2000">
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },

})
/* ============================ sliders.js END ================================================================= */
   // <img loading="lazy" src="/static/app/img/gallery-3.jpg" alt="">

